.k-splitbar-horizontal {
    width: 15px;
    background: rgba(0, 0, 0, 0.04);
    z-index: 1199; /* 1 below the side menu */
}

.k-splitbar-vertical {
    height: 20px;
    background-color: rgba(0, 0, 0, 0.03);
    z-index: 1199; /* 1 below the side menu */
}

.k-ghost-splitbar .k-icon,
.k-splitbar .k-icon {
    font-size: 20px;
    display: unset;
}

.k-splitter-flex {
    /* width: unset;
    height: unset; */
}
